import { BrowserRouter as Router, Link } from 'react-router-dom'
import './index.scss'

function Progress({ progress, background }) {
  return (
    <div className="Progress">
      <div
        className="Inner"
        style={{ background: background, width: `${progress}%` }}
      />
    </div>
  )
}

// <li>
//   <button stype="button" onClick={signOut}>
//     Sign Out
//   </button>
// </li>;

export default Progress
