import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import request from 'superagent'
import firebase from 'firebase'
import { addActivity, storage } from '../../config'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import Progress from '../Progress'
import classNames from 'classnames'
import { UiContext } from '../../context/_ui'

import './style.scss'

const BlockInputUploadMultiple = ({
  uid,
  frames,
  label,
  background,
  people,
}) => {
  const { ui } = useContext(UiContext)
  const [photoUrl, setPhotoUrl] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [uploadTo, setUploadTo] = useState(0)

  const [text, setText] = useState(null)
  const [subText, setSubText] = useState(null)
  const [progress, setProgress] = useState(null)

  const finishFunction = (name, filename) => {
    console.log({
      file: name,
      src: fileName,
      date: moment().unix(),
      uid: ui.user.uid,
      label: label,
      name: ui.user.name,
      pic: ui.user.pic,
    })
    addActivity(
      `activity`,
      {
        file: name,
        src: fileName,
        date: moment().unix(),
        uid: ui.user.uid,
        label: label,
        name: ui.user.name,
        pic: ui.user.pic,
      },
      (err) => console.log(err)
    ).then(() => {
      setProgress(100)
      setText('Done!')
      setTimeout(() => {
        setProgress(null)
        setText(null)
        setSubText(null)
      }, 1000)
    })

    // var storageRef = storage.ref();
    // var desertRef = storageRef.child(`${uid}/${filename}`);
    // desertRef
    //   .delete()
    //   .then(() => {
    //     console.log("Cloud reference deleted");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  const emailPhoto = (name) => {
    const filename = `https://storage.googleapis.com/allard-family-photos.appspot.com/${uid}/${name}`
    request
      .post(
        'https://us-central1-allard-family-photos.cloudfunctions.net/newSendEmail'
      )
      .send({
        to: frames[uploadTo].email,
        attachment: filename,
        subject: frames[uploadTo].frameId,
      })
      .then((res) => {
        setProgress((uploadTo / frames.length) * 100)
        setSubText(`Sending to ${frames[uploadTo].label}'s`)
        setPhotoUrl(name)
        setFileName(filename)
        setUploadTo(uploadTo + 1)
      })
  }

  useEffect(() => {
    if (uploadTo !== frames.length) {
      if (uploadTo > 0) emailPhoto(photoUrl)
    } else {
      finishFunction(photoUrl)
      setTimeout(() => {
        setProgress(null)
        setUploadTo(0)
        setProgress(null)
        setPhotoUrl(null)
        setSubText(null)
        setText(null)
      }, 3000)
    }
  }, [uploadTo])

  const onFilesDropped = (files) => {
    setProgress(0)
    const file = files[0]
    if (file.size / 1000 > 10000)
      return alert('File size to big! Please upload an image less than 10mb.')
    const extension = file.name.split('.').pop()
    const strippedName = file.name.replace(/[^A-Z0-9]/gi, '_')
    const name = `${strippedName}-${uuidv4()}.${extension}`
    var storageRef = firebase.storage().ref(uid + '/' + name)
    const uploadTask = storageRef.put(file) // <--- See the difference here
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        setProgress(progress)
      },
      (error) => {
        console.log(error)
      },
      () => {
        setProgress(0)
        setText('Sending')
        setSubText(`Sending to ${frames[uploadTo].label}'s`)
        emailPhoto(name)
      }
    )
  }

  const onSelectPhoto = (e) => {
    setProgress(0)
    setText('Uploading')
    setSubText('Uploading photo to server...')

    const filesToUpload = e.target.files
    const files = _.values(filesToUpload)
    onFilesDropped(files)
    e.preventDefault()
  }

  const classes = classNames({
    'file-uploader-wrapper': true,
    uploading: progress !== null,
  })

  return (
    <div className={classes} style={{ background: background }}>
      {progress !== null && (
        <img className="loader-img" alt="" src="/assets/oval.svg" />
      )}
      <div className="file-uploader">
        <div style={{ flex: 1 }}>
          <h4>{text ? text : label}</h4>

          {subText && <p>{subText}</p>}

          {!subText && <p>Upload or take a photo!</p>}
          {progress !== null && (
            <Progress progress={progress} background={background} />
          )}
          {progress === null &&
            people.map((item, i) => (
              <img
                key={`mi${i}`}
                className="profilePic"
                alt=""
                src={item}
                width="20"
              />
            ))}
        </div>
        {progress === null && (
          <div style={{ width: 80, height: 80 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style={{ enableBackground: 'new 0 0 100 100' }}
              xmlSpace="preserve"
            >
              <path
                fill={background}
                d="M62,32.3c-9.8,0-17.7,7.9-17.7,17.7S52.2,67.7,62,67.7S79.7,59.8,79.7,50v0C79.7,40.2,71.8,32.3,62,32.3z"
              />
              <path
                fill={background}
                d="M87,15H13C7.2,15,2.5,19.7,2.5,25.5v49C2.5,80.3,7.2,85,13,85h74c5.8,0,10.5-4.7,10.5-10.5v-49C97.5,19.7,92.8,15,87,15z   M36.2,37.7c0,1.4-1.1,2.5-2.5,2.5H15c-1.4,0-2.5-1.1-2.5-2.5V27.5c0-1.4,1.1-2.5,2.5-2.5h18.7c1.4,0,2.5,1.1,2.5,2.5V37.7z   M62,72.7c-12.5,0-22.7-10.2-22.7-22.7S49.5,27.3,62,27.3S84.7,37.5,84.7,50C84.7,62.5,74.5,72.7,62,72.7z"
              />
            </svg>
            <label>
              <input type="file" onChange={onSelectPhoto} />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default BlockInputUploadMultiple
