import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import UiProvider from './context/_ui'
import App from './Components/App'
import Home from './Pages/Home'
import Account from './Pages/Account'
import Upload from './Pages/Upload'
import Activity from './Pages/Activity'
import reportWebVitals from './reportWebVitals'

import './index.scss'

ReactDOM.render(
  <UiProvider>
    <React.Fragment>
      <Router>
        <App>
          <Route path="/" exact component={Home} />
          <Route path="/account" component={Account} />
          <Route path="/upload" component={Upload} />
          <Route path="/activity" component={Activity} />
        </App>
      </Router>
    </React.Fragment>
  </UiProvider>,
  document.getElementById('root')
)

// <Route path="/sign-up" component={SignUp} />

reportWebVitals()
