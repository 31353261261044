import React, { useState, useContext } from 'react'
import { firebaseApp } from '../../config'

import { UiContext } from '../../context/_ui'

import './style.scss'

const LoginForm = () => {
  const { ui, setUi } = useContext(UiContext)
  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const login = (e) => {
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .catch((error) => {
        console.log(error)
        return setUi({
          ...ui,
          modal: {
            show: true,
            title: 'Hmmmm...',
            message: `${error.message} If the problem keeps happening contact Dave and he'll sort it out.`,
          },
        })
      })
    e.preventDefault()
  }

  return (
    <div className="LoginForm pad">
      <div className="panel">
        <div className="panel-heading">Login</div>
        <div className="panel-body">
          <form onSubmit={login}>
            <div className="form-group">
              <div className="cols-sm-10">
                <input
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Enter your Email"
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <div className="cols-sm-10">
                <input
                  type="password"
                  name="confirm"
                  id="confirm"
                  placeholder="Enter your Password"
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                />
              </div>
            </div>

            <button id="button" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
