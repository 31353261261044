import firebase from "firebase";

export const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA0JdIdkQV-C46DZWojnmNLH50RDMT715g",
  authDomain: "allard-family-photos.firebaseapp.com",
  databaseURL: "https://allard-family-photos.firebaseio.com",
  projectId: "allard-family-photos",
  storageBucket: "allard-family-photos.appspot.com",
});

export const database = firebase.database();
export const storage = firebase.storage();

export const addActivity = async (key, data) => {
  const add = new Promise((resolve, reject) => {
    database
      .ref(`${key}`)
      .push(data)
      .then((e) => resolve(e));
  });
  const result = await add;
  return result;
};

export const removeSingle = async (key, node) => {
  const rem = new Promise((resolve, reject) => {
    database
      .ref(`${key}/${node}`)
      .remove()
      .then((e) => resolve(e));
  });
  const result = await rem;
  return result;
};

export const add = async (key, uid, data) => {
  const add = new Promise((resolve, reject) => {
    database
      .ref(`${key}/${uid}`)
      .push(data)
      .then((e) => resolve(e));
  });
  const result = await add;
  return result;
};

export const remove = async (key, uid, node) => {
  const rem = new Promise((resolve, reject) => {
    database
      .ref(`${key}/${uid}/${node}`)
      .remove()
      .then((e) => resolve(e));
  });
  const result = await rem;
  return result;
};

export const getSingle = async (key, uid, id) => {
  if (id)
    return await database
      .ref(`${key}`)
      .child(`${uid}/${id}`)
      .once("value")
      .then((_) => _.val());
  if (!id)
    return await database
      .ref(`${key}`)
      .child(uid)
      .once("value")
      .then((_) => _.val());
};

export const update = async (url, data) => {
  const search = new Promise((resolve, reject) => {
    database
      .ref(url)
      .update(data)
      .then((e) => resolve(e));
  });
  const result = await search;
  return result;
};

export const set = async (url, data) => {
  const set = new Promise((resolve, reject) => {
    database
      .ref(url)
      .set(data)
      .then((e) => resolve(e));
  });
  const result = await set;
  return result;
};

export const setMultiple = async (config) => {
  const set = new Promise((resolve, reject) => {
    Promise.all(
      config.map((data) =>
        database
          .ref(data.url)
          .set(data.data)
          .then((e) => resolve(e))
      )
    );
  });
  const result = await set;
  return result;
};

export const getMultiple = async (key, uids) => {
  return await Promise.all(
    uids.map((id) => database.ref(key).child(id).limitToLast(10).once("value"))
  ).then((values) => values.map((_) => _.val()));
};

export const checkEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const nav = [
  {
    to: "/upload",
    icon: "icon-trending-up",
    title: "Upload",
  },
  // {
  //   to: '/discover',
  //   icon: 'icon-bulb',
  //   title: 'Discover',
  //   // userDate: true,
  // },
  {
    to: "/account",
    icon: "icon-settings",
    title: "Password",
    // count: 2,
  },

  // {
  //   to: '/workout',
  //   icon: 'icon-clock',
  //   title: 'Workout',
  // },
];
