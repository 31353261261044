import React, { useState, useEffect, useContext } from 'react'
import { ApolloProvider } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { client } from '../../ApolloClient/client'
import { firebaseApp } from '../../config'
import { UiContext } from '../../context/_ui'
import SidebarBottom from '../SidebarBottom'
import LoginForm from '../LoginForm'
import Welcome from '../Welcome'
import Header from '../Header'
import Loader from '../Loader'
import classNames from 'classnames'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import './style.scss'

import { database } from '../../config'

function App({ history, children }) {
  const [isLoading, setIsLoading] = useState(true)
  const { ui, setUi } = useContext(UiContext)

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        let ref = database.ref(`users/${user.uid}`)
        ref.on('value', (snapshot) => {
          if (snapshot.val()) setUi({ ...ui, user: snapshot.val() })
        })
        history.push('/upload')
      } else {
        setUi({ ...ui, user: { uid: null } })
        history.push('/')
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1600)
    })
  }, [])

  const classes = classNames({
    App: true,
    LoggedIn: ui.user.uid,
  })
  return (
    <ApolloProvider client={client}>
      <Header user={ui.user} signOut={() => firebaseApp.auth().signOut()} />

      {!isLoading && !ui.user.uid && (
        <>
          <LoginForm />
          <Welcome />
        </>
      )}

      {!isLoading && ui.user.uid && (
        <>
          <div className={classes}>
            <div className="main">{children}</div>
          </div>
          <SidebarBottom location={null} uid={ui.user.uid} />
        </>
      )}
      {isLoading && (
        <>
          <br />
          <Loader />
        </>
      )}
      <Rodal
        animation="slideUp"
        visible={ui.modal.show}
        onClose={() => {
          setUi({
            ...ui,
            modal: {
              show: false,
            },
          })
        }}
      >
        <div>
          <h2>{ui.modal.title}</h2>
          <p>{ui.modal.message}</p>
        </div>
      </Rodal>
    </ApolloProvider>
  )
}

{
  /*
  <button
        onClick={() =>
          setUi({
            ...ui,
            modal: {
              show: true,
              title: "asdfasdf",
              message: "asfd asd fas dfa sdf sadf",
            },
          })
        }
      >
        Open Modal
      </button>
       <button stype="button" onClick={() => open()}>
  Open
</button>; */
}

export default withRouter(App)
