import './index.scss'
import moment from 'moment'

function ItemCard({
  uuid,
  id,
  date,
  label,
  name,
  file,
  src,
  uid,
  pic,
  showDelete,
}) {
  return (
    <article className="ItemCard">
      <div className="header">
        <div>
          <img src={pic} alt="" width="40" />{' '}
          <span>
            Sent to <strong>{label}'s</strong>
            <br />
            <small>{moment.unix(date).fromNow()}</small>
          </span>
        </div>
        {uuid === uid && (
          <button
            id="button"
            type="submit"
            className="danger"
            onClick={() => showDelete({ id: id, name: file })}
          >
            X
          </button>
        )}
      </div>

      <figure>
        <img src={src} alt="" width="100%" />
      </figure>
    </article>
  )
}

export default ItemCard
