import React, { useState, useContext } from 'react'
import { firebaseApp } from '../../config'
import { UiContext } from '../../context/_ui'

const initialState = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
}

const LoginForm = () => {
  const { ui, setUi } = useContext(UiContext)
  const [state, setState] = useState(initialState)

  const changePassword = (e) => {
    e.preventDefault()
    const { password, newPassword, confirmNewPassword } = state
    if (newPassword !== confirmNewPassword) {
      return setUi({
        ...ui,
        modal: {
          show: true,
          title: 'Hmmmm...',
          message:
            'Your passwords do not match. Please try again. Probably best to contact Dave if your having problems and he will change it for you.',
        },
      })
    }
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(ui.user.email, password)
      .then((user) => {
        firebaseApp
          .auth()
          .currentUser.updatePassword(newPassword)
          .then(() => {
            setState(initialState)
            setUi({
              ...ui,
              modal: {
                show: true,
                title: 'Success!',
                message: 'Your password has been changed.',
              },
            })
          })
          .catch((err) => {
            setState(initialState)
            setUi({
              ...ui,
              modal: {
                show: true,
                title: 'Success!',
                message: `${err.message} Probably best to contact Dave if your having problems and he will change it for you.`,
              },
            })
          })
        e.preventDefault()
      })
      .catch((err) => {
        setUi({
          ...ui,
          modal: {
            show: true,
            title: 'Hmmmm...',
            message: `${err.message} Probably best to contact Dave if your having problems and he will change it for you.`,
          },
        })
        console.log()
        setState(initialState)
      })
  }

  return (
    <div>
      <div className="panel">
        <div className="panel-heading">Change Password</div>
        <div className="panel-body">
          <form onSubmit={changePassword}>
            <div className="form-group">
              <label htmlFor="confirm" className="cols-sm-2 control-label">
                Current password
              </label>
              <div className="cols-sm-10">
                <input
                  id="confirm"
                  name="confirm"
                  type="password"
                  placeholder="Enter your Password"
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="confirm" className="cols-sm-2 control-label">
                New password
              </label>
              <div className="cols-sm-10">
                <input
                  id="confirm"
                  name="confirm"
                  type="password"
                  placeholder="Enter your Password"
                  onChange={(e) =>
                    setState({ ...state, newPassword: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="confirm" className="cols-sm-2 control-label">
                Confirm new password
              </label>
              <div className="cols-sm-10">
                <input
                  id="confirm"
                  name="confirm"
                  type="password"
                  placeholder="Enter your Password"
                  onChange={(e) =>
                    setState({ ...state, confirmNewPassword: e.target.value })
                  }
                />
              </div>
            </div>

            <button id="button" type="submit">
              Change
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
