import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.scss";

function Header({ user, signOut }) {
  const logoTo = user ? "/upload" : "login";
  return (
    <header className="Header">
      <nav>
        <ul>
          <li>
            <Link to={logoTo}>
              <img
                className="logo"
                src="/assets/FamFrames.svg"
                alt=""
                height="60"
              />
              <small>Instantly share photos with the family!</small>
            </Link>
          </li>
          <li>
            <Link to="/account">
              <img src={user.pic} alt="" width="60" />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

// <li>
//   <button stype="button" onClick={signOut}>
//     Sign Out
//   </button>
// </li>;

export default Header;
