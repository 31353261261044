import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import BlockInputUpload from '../../Components/BlockInputUpload'
import BlockInputUploadMultiple from '../../Components/BlockInputUploadMultiple'

import { UiContext } from '../../context/_ui'

import './style.scss'

const Upload = ({ notify, id, uid, userData, signOut }) => {
  const { ui, setUi } = useContext(UiContext)

  return (
    <div className="Upload">
      <div className="bg-highlight">
        <BlockInputUploadMultiple
          uid={ui.user.uid}
          notify={notify}
          frames={ui.frames}
          label="Everyones Frame"
          background="#DB4437"
          people={[
            'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/mumdad.png',
            'https://storage.googleapis.com/allard-family-photos.appspot.com/mark-sarah.png',
            'https://storage.googleapis.com/allard-family-photos.appspot.com/cathmark.png',
            'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/emrob.png',
            //  'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/dave.png',
          ]}
        />

        {ui.frames.map((frame, i) => (
          <BlockInputUpload
            key={`frame${i}`}
            uid={ui.user.uid}
            notify={notify}
            label={frame.label}
            emailTo={frame.email}
            emailSubject={frame.frameId}
            people={frame.people}
            background={frame.background}
          />
        ))}
      </div>
      <div className="btn-group">
        <button
          stype="button"
          className="danger"
          onClick={() => (window.location = window.location)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="none"
                stroke="#fff"
                strokeWidth="6"
                strokeMiterlimit="10"
                d="M37.6,73.5c-8.8-4.1-14.8-12.9-14.8-23.2   c0-13.4,10.3-24.4,23.5-25.5"
              />
              <polyline
                fill="#fff"
                points="56.9,26 45.2,32.7 45.2,16.7 59.1,24.7 56.9,26  "
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#fff"
                strokeWidth="6"
                strokeMiterlimit="10"
                d="M62.4,26.5c8.8,4.1,14.9,12.9,14.9,23.2   c0,13.4-10.3,24.4-23.5,25.5"
              />
              <polyline
                fill="#fff"
                points="43.1,74 54.8,67.3 54.8,83.3 40.9,75.3 43.1,74  "
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  )
}

Upload.propTypes = {
  id: PropTypes.string,
  uid: PropTypes.string,
  user: PropTypes.object,
  userData: PropTypes.object,
}

Upload.defaultProps = {
  id: null,
  uid: '',
  user: {},
  userData: {},
}

export default Upload

// import BlockInputUpload from '../../Components/BlockInputUpload'
// import request from 'superagent'

// const Upload = () => {

//     const sendEmail = () => {
//         request
//             .post('https://us-central1-allard-family-photos.cloudfunctions.net/sendFamilyEmail')
//             .send({ dest: 'dallardtech@hotmail.com' })
//             .then(res => {
//                 console.log(res);
//             });
//     }

//     return (
//         <div>
//             <p>Upload</p>
//             <BlockInputUpload uid="7eqDShu057S7ncmfObYMTqfX6DL2" notify={() => console.log('Test')} />
//             <button id="button" onClick={sendEmail}>Send email</button>
//         </div>
//     );
// };

// export default Upload
