import ChangePasswordForm from '../../Components/ChangePasswordForm'
import { firebaseApp } from '../../config'
const Account = () => {
  return (
    <div className="pad">
      <ChangePasswordForm />
      <br />
      <div className="panel">
        <div className="panel-heading">
          <div>Account</div>
          <button
            stype="button"
            className="danger"
            onClick={() => firebaseApp.auth().signOut()}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  )
}

export default Account
