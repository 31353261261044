import PropTypes from 'prop-types'
import React, { useState, createContext } from 'react'

export const UiContext = createContext()

const UiProvider = ({ children }) => {
  const [ui, setUi] = useState({
    modal: {
      show: false,
      title: 'Test modal title',
      message: 'sdfasdf saf dsa dfa sdf as fsa',
    },
    user: {
      uid: null,
      name: '',
      pic: '',
    },
    frames: [
      {
        label: 'Mum & Dad',
        frameId: 'bd3L3LS2',
        email: 'mumdadallard@ourphoto.cn',
        background: '#db3987',
        people: [
          // '/assets/family.svg',
          'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/mum.png',
          'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/dad2.png',
        ],
      },

      {
        label: 'Mark & Sarah',
        frameId: 'bd3LKYSQ',
        email: 'markallard@ourphoto.cn',
        background: '#7537db',
        people: [
          'https://storage.googleapis.com/allard-family-photos.appspot.com/mark.png',
          'https://storage.googleapis.com/allard-family-photos.appspot.com/sarah.png',
        ],
      },
      {
        label: 'Cath & Mark',
        frameId: 'bd3Q9USR',
        email: 'cathallard@ourphoto.cn',
        background: '#0F9D58',
        people: [
          'https://storage.googleapis.com/allard-family-photos.appspot.com/cath.png',
          'https://storage.googleapis.com/allard-family-photos.appspot.com/markm.png',
        ],
      },
      {
        label: 'Em & Rob',
        frameId: 'bd3GFYSB',
        email: 'daveallard@ourphoto.cn',
        background: '#4285F4',
        people: [
          'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/em.png',
          'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/rob.png',
        ],
      },
      // {
      //   label: 'Dave',
      //   frameId: 'bd3GFYSB',
      //   email: 'daveallard@ourphoto.cn',
      //   background: '#f4b401',
      //   people: [
      //     'https://storage.googleapis.com/allard-family-photos.appspot.com/7eqDShu057S7ncmfObYMTqfX6DL2/dave.png',
      //   ],
      //   uid: '7eqDShu057S7ncmfObYMTqfX6DL2',
      // },
    ],
  })

  return (
    <UiContext.Provider
      value={{
        ui,
        setUi,
      }}
    >
      {children}
    </UiContext.Provider>
  )
}

UiProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UiProvider
