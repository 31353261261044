import React, { useEffect, useState, useContext } from 'react'
import { database, removeSingle, storage } from '../../config'
import ItemCard from '../../Components/ItemCard'
import Loader from '../../Components/Loader'
import ReactPullToRefresh from 'react-pull-to-refresh'
import { UiContext } from '../../context/_ui'
import classNames from 'classnames'
// import { add, , , storage, firebase } from "../../config";
import { Waypoint } from 'react-waypoint'

import Rodal from 'rodal'

import './style.scss'

const Activity = ({ notify, id, userData, signOut }) => {
  const itemsPerPage = 10
  const { ui } = useContext(UiContext)
  const [feed, setFeed] = useState([])
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getFeed = (lastDate) => {
    let ref = database.ref(`activity`).limitToLast(itemsPerPage)

    ref.on('value', (snapshot) => {
      const arrWithKeys = []
      snapshot.forEach((item) => {
        arrWithKeys.push({
          id: item.ref.key,
          ...item.val(),
        })
      })
      if (snapshot.val()) setFeed(arrWithKeys.reverse())
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    })
  }

  useEffect(() => {
    getFeed()
  }, [])

  const deleteFunc = (file) => {
    var storageRef = storage.ref()
    var desertRef = storageRef.child(`/${ui.user.uid}/${file.name}`)
    desertRef
      .delete()
      .then(() => {
        removeSingle('activity', file.id).then(() => {
          const clone = feed.filter((_) => _.id !== file.id)
          setFeed(clone)

          setShowDeleteModal(false)
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleRefresh = (resolve, reject) => {
    setIsLoading(true)
    getFeed()
    resolve()
  }

  const classes = classNames({
    Upload: true,
    isLoading: isLoading,
  })

  const loadMore = () => {}

  return (
    <div className={classes}>
      <Rodal
        animation="slideUp"
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <div>
          <h2>Delete from activity?</h2>
          {showDeleteModal !== 'deleted' && (
            <div className="button-group">
              <button stype="button" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </button>
              <button
                stype="button"
                className="danger"
                onClick={() => deleteFunc(showDeleteModal)}
              >
                Delete?
              </button>
            </div>
          )}
        </div>
      </Rodal>
      <div className="LoaderContainer">
        <Loader />
      </div>
      <div className="ContentContainer">
        <ReactPullToRefresh onRefresh={handleRefresh}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="none"
                stroke="#fff"
                strokeWidth="6"
                strokeMiterlimit="10"
                d="M37.6,73.5c-8.8-4.1-14.8-12.9-14.8-23.2   c0-13.4,10.3-24.4,23.5-25.5"
              />
              <polyline
                fill="#fff"
                points="56.9,26 45.2,32.7 45.2,16.7 59.1,24.7 56.9,26  "
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#fff"
                strokeWidth="6"
                strokeMiterlimit="10"
                d="M62.4,26.5c8.8,4.1,14.9,12.9,14.9,23.2   c0,13.4-10.3,24.4-23.5,25.5"
              />
              <polyline
                fill="#fff"
                points="43.1,74 54.8,67.3 54.8,83.3 40.9,75.3 43.1,74  "
              />
            </g>
          </svg>
        </ReactPullToRefresh>
        <div className="text-center">
          {feed.length === 0 && <p>No activity!</p>}
        </div>
        {feed.map((item) => (
          <ItemCard
            key={item.id}
            {...item}
            uuid={ui.user.uid}
            showDelete={(file) => setShowDeleteModal(file)}
          />
        ))}
      </div>
    </div>
  )
}
// <button onClick={() => getFeed(true)}>sdfsadf</button>
// {
//   !isLoading && (
//     <div className="LoadMore">
//       <Waypoint onEnter={() => console.log('asd')} />
//     </div>
//   )
// }

export default Activity
