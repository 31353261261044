import React, { useState } from 'react'
import moment from 'moment'
import { nav } from '../../config'
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom'
import './style.scss'

const SidebarBottom = ({ history }) => {
  // const [active, setActive] = useState(0)

  const changeRoute = (i, path) => {
    // setActive(i)
    history.push(path)
  }

  const active = history.location.pathname === '/upload' ? 0 : 1
  return (
    <div className="SidebarBottom">
      <div
        className="marker"
        style={{
          transform: `translateX(calc(${active * 50}vw - 0.5vw))`,
          opacity: history.location.pathname !== '/account' ? 1 : 0,
        }}
      />
      <ul className="list-unstyled">
        <li>
          <Link
            to="/upload"
            onClick={() => changeRoute(0, '/upload')}
            onTouchStart={() => changeRoute(0, '/upload')}
            className={
              history.location.pathname === '/upload' ? 'active-nav' : ''
            }
          >
            <div className="circle" />
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style={{ enableBackground: 'new 0 0 100 100' }}
                xmlSpace="preserve"
              >
                <path d="M62,32.3c-9.8,0-17.7,7.9-17.7,17.7S52.2,67.7,62,67.7S79.7,59.8,79.7,50v0C79.7,40.2,71.8,32.3,62,32.3z" />
                <path d="M87,15H13C7.2,15,2.5,19.7,2.5,25.5v49C2.5,80.3,7.2,85,13,85h74c5.8,0,10.5-4.7,10.5-10.5v-49C97.5,19.7,92.8,15,87,15z   M36.2,37.7c0,1.4-1.1,2.5-2.5,2.5H15c-1.4,0-2.5-1.1-2.5-2.5V27.5c0-1.4,1.1-2.5,2.5-2.5h18.7c1.4,0,2.5,1.1,2.5,2.5V37.7z   M62,72.7c-12.5,0-22.7-10.2-22.7-22.7S49.5,27.3,62,27.3S84.7,37.5,84.7,50C84.7,62.5,74.5,72.7,62,72.7z" />
              </svg>
              <span>Upload</span>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/activity"
            onClick={() => changeRoute(1, '/activity')}
            onTouchStart={() => changeRoute(1, '/activity')}
            className={
              history.location.pathname === '/activity' ? 'active-nav' : ''
            }
          >
            <div className="circle" />
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 125"
                x="0px"
                y="0px"
              >
                <path d="M18.11,63a3.57,3.57,0,0,1,3.28-3.57l12.7-1.07,6.64-15.94a4.73,4.73,0,0,1,4.77-2.87,4.68,4.68,0,0,1,4.17,3.71L54.89,68,65.4,20.18a4.69,4.69,0,0,1,4.49-3.69A4.64,4.64,0,0,1,74.54,20L84.75,58.53l10.86,1.05a3.62,3.62,0,0,1-.08,7.21L81.16,67.9h0a4.71,4.71,0,0,1-4.58-3.5L70.42,41.16l-11,50.17A4.73,4.73,0,0,1,54.79,95h0a4.7,4.7,0,0,1-4.58-3.71L43.59,60l-2,4.84a4.69,4.69,0,0,1-4.34,2.89l-15.8-1.15A3.6,3.6,0,0,1,18.11,63Zm2.3-15.45h8.75a4.65,4.65,0,1,0,0-9.29H20.41a4.65,4.65,0,0,0,0,9.29ZM50.86,21.63H20.41a4.65,4.65,0,0,0,0,9.29H50.86a4.65,4.65,0,1,0,0-9.29ZM50.86,5H20.41a4.65,4.65,0,0,0,0,9.29H50.86a4.65,4.65,0,1,0,0-9.29ZM5.79,5a4.65,4.65,0,1,0,4.65,4.65A4.65,4.65,0,0,0,5.79,5Zm0,16.64a4.65,4.65,0,1,0,4.65,4.65A4.65,4.65,0,0,0,5.79,21.63Zm0,16.64a4.65,4.65,0,1,0,4.65,4.65A4.65,4.65,0,0,0,5.79,38.28Z" />
              </svg>
              <span>Activity</span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  )
}

// ;<li>
//   <Link
//     to="/account"
//     onClick={() => changeRoute(2, '/account')}
//     onTouchStart={() => changeRoute(2, '/account')}
//     className={active === 2 ? 'active-nav' : ''}
//   >
//     <div className="circle" />
//     <div>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         version="1.1"
//         x="0px"
//         y="-10px"
//         viewBox="0 0 100 100"
//         enableBackground="new 0 0 100 80"
//         xmlSpace="preserve"
//       >
//         <path d="M67.705,55.164c5.624-4.764,9.191-13.28,9.191-27.959C76.896,12.18,64.715,0,49.691,0C34.667,0,22.488,12.18,22.488,27.204  c0,15.35,3.879,23.997,9.957,28.623C19.696,64.673,7.52,78.929,11.828,86.846c9.271,17.032,70.451,18.038,77.58,0  C92.785,78.3,80.383,63.81,67.705,55.164z" />
//       </svg>
//       <span>Upload</span>
//     </div>
//   </Link>
// </li>

export default withRouter(SidebarBottom)
